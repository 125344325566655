import React from "react"
import { Seo } from "components/seo"
import qs from "qs"
import useDebounce from "utils/useDebounce"
import StickyBox from "react-sticky-box"
import { Disclosure } from "@headlessui/react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  RefinementList,
  SortBy,
  Pagination,
  ClearRefinements,
  Configure,
  Hits,
  HitsPerPage,
  Panel,
  CurrentRefinements,
  NumericMenu,
  ToggleRefinement,
  Stats,
  connectStateResults,
  SearchBox,
  // connectRefinementList
} from "react-instantsearch-dom"
import "./index.css"

import { Layout } from "components/layouts/Layout/layout"
import ProductCard from "components/elements/ProductCard"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const createUrlParams = (state) => `?${qs.stringify(state)}`

const searchStateToUrl = ({ location }, searchState) =>
  searchState ? `${location.pathname}${createUrlParams(searchState)}` : ""

let searchTerm = ""
const urlToSearchState = ({ search }) => {
  const searchState = qs.parse(search.slice(1))
  searchTerm = searchState?.search ?? ""
  return searchState
}

const DEBOUNCE_TIME = 800

const Results = connectStateResults(({ searchState }) =>
  searchState ? <Hits hitComponent={Hit} /> : null
)

function Hit({ hit }) {
  const priceRangeV2 = {
    minVariantPrice: {
      amount: hit.price,
    },
  }

  const images = []

  const product = {
    title: hit.title,
    image: hit.image,
    vim: hit.tags.includes("VIM"),
    price: hit.compare_at_price,
    // salePrice: hit.salePrice,
    reviews: 0,
    images: images,
    priceRangeV2: priceRangeV2,
    salePrice: hit.price,
    handle: hit.handle,
    productType: hit.product_type,
    meta: hit.meta,
    inventoryAvailable: hit.inventory_available,
    inventoryPolicy: hit.inventory_policy,
    reviewsAverage: hit?.meta?.yotpo?.reviews_average,
    reviewsCount: hit?.meta?.yotpo?.reviews_count,
  }

  return (
    <>
      <ProductCard product={product} />
    </>
  )
}

const Filters = () => {
  return (
    <div className="p-6 pt-8 rounded-md lg:p-8 bg-idc-light">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold leading-7 text-idc-title">Filters</h2>
        <ClearRefinements className="text-sm font-medium leading-tight uppercase text-idc-orange" />
      </div>
      <div className="space-y-4">
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Brands</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="vendor"
                      operator="and"
                      showMore
                      limit={12}
                      showMoreLimit={300}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Price</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <NumericMenu
                      attribute="price"
                      items={[
                        { label: "All" },
                        { label: "Under $100", end: 100 },
                        { label: "$100 - $500", start: 100, end: 500 },
                        { label: "$500 - $1,000", start: 500, end: 1000 },
                        { label: "$1,000 - $2,500", start: 1000, end: 2500 },
                        { label: "$2,500 & Above", start: 2500 },
                      ]}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Color</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.color"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Colour</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.colour"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Colours</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.colours"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Size</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.size"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Portafilter</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.portafilter"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Base Size</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.base_size"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Customization</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <RefinementList
                      attribute="options.customization"
                      operator="and"
                      showMore
                      limit={10}
                      showMoreLimit={50}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
        <Panel>
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full">
                  <h3 className="flex items-center justify-between w-full ais-Panel-header group">
                    <span>Availability</span>
                    <svg
                      className={`text-idc-icon group-hover:text-idc-orange fill-current ${
                        open ? "" : "transform rotate-180"
                      }`}
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.628357 7.2053L1.37165 7.87427L7 1.62054L12.6284 7.87427L13.3717 7.2053L7 0.125696L0.628357 7.2053Z"
                      />
                    </svg>
                  </h3>
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel
                    unmount={false}
                    className={`${open ? "mb-6" : ""}`}
                  >
                    <ToggleRefinement
                      attribute="inventory_available"
                      label="Show only availabile"
                      value={true}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        </Panel>
      </div>
    </div>
  )
}

const SearchListing = ({ location }) => {
  const [searchState, setSearchState] = React.useState(
    urlToSearchState(location)
  )

  const debouncedSearchState = useDebounce(searchState, DEBOUNCE_TIME)

  const onSearchStateChange = (updatedSearchState) => {
    setSearchState(updatedSearchState)
  }

  const breakpoints = useBreakpoint()

  React.useEffect(() => {
    if (debouncedSearchState) {
      // I tried using setState here, but I found that that broke the "Back"
      // button. Ultimately this means that the search state overwrites
      // itself as the user refines their search. There is no "undo" to the
      // refinements.
      window.history.replaceState(
        debouncedSearchState,
        "",
        searchStateToUrl({ location }, debouncedSearchState)
      )
    }
  }, [debouncedSearchState, location])
  React.useEffect(() => {
    if (
      searchState.page ||
      searchState.refinementList ||
      searchState.configure
    ) {
      setTimeout(() => {
        scrollTo(document.querySelector(".ais-Hits-list").offsetTop, 400)
      }, 400)
    }
  }, [searchState.page, searchState.refinementList, searchState.configure])

  return (
    <Layout>
      <Seo title="Search" />
      <div className="max-w-[1700px] px-4 mx-auto">
        <div className="my-6">
          <h1 className="text-3xl font-bold text-center">Search Results</h1>
        </div>

        <InstantSearch
          searchClient={searchClient}
          indexName="idc_products"
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
          createUrl={createUrlParams}
        >
          <Configure
            distinct
            facetingAfterDistinct="true"
            filters=""
            // filters={`(collections:${slugify("Espresso Machines")}) AND (tags:consumer)`}
            enablePersonalization={false}
          />
          <SearchBox
            aria-hidden="true"
            className="hidden searchbox"
            defaultRefinement={`${searchTerm}`}
          />
          <div className="relative items-start justify-between w-full md:flex md:flex-row">
            {breakpoints.md ? null : (
              <StickyBox offsetTop={16} offsetBottom={16}>
                <div className="w-full md:w-64 lg:w-80 2xl:w-96 min-w-[280px]">
                  <Filters />
                </div>
              </StickyBox>
            )}

            <div className="relative z-10 bg-white grow md:pl-8 xl:pl-12 2xl:pl-16">
              <div className="flex flex-col items-center justify-start w-full pb-2 md:flex-row md:justify-end lg:justify-between md:py-4">
                <div className="hidden lg:block">
                  <Stats
                    translations={{
                      stats(nbHits, nbSortedHits, areHitsSorted) {
                        return areHitsSorted && nbHits !== nbSortedHits
                          ? `${nbSortedHits!.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()} found`
                          : `${nbHits.toLocaleString()} results found`
                      },
                    }}
                  />
                </div>
                <div className="flex justify-between w-full md:w-auto md:space-x-8">
                  {breakpoints.md ? (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={
                              "inline-block justify-self-start cursor-pointer py-2.5 px-5 outline-none bg-transparent bg-white border hover:border-idc-border rounded hover:shadow-lg"
                            }
                          >
                            Filter
                          </Disclosure.Button>
                          <Disclosure.Panel unmount={false}>
                            <div className="bg-idc-light fixed inset-0 max-w-[300px] z-30 overflow-auto overscroll-contain">
                              <Filters />
                              <div className="p-6 pt-0 -mt-12">
                                <Disclosure.Button
                                  as="div"
                                  className="inline-block w-full px-8 py-3 mt-8 text-center transition duration-300 ease-in-out rounded-full bg-idc-orange hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
                                >
                                  <span className="m-0 text-base font-bold text-white">
                                    View Results
                                  </span>
                                </Disclosure.Button>
                              </div>
                            </div>
                            <Disclosure.Button
                              as="div"
                              className="fixed inset-0 z-30 bg-idc-title opacity-30"
                            ></Disclosure.Button>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : null}
                  <div className="flex md:space-x-8">
                    <div className="flex items-center space-x-2">
                      <span className="hidden w-16 text-sm md:inline-block md:w-auto text-idc-body">
                        Sort by
                      </span>
                      <SortBy
                        className="pr-3 bg-white border rounded hover:border-idc-border hover:shadow-lg"
                        defaultRefinement="idc_products"
                        items={[
                          {
                            label: "Featured",
                            value: "idc_products",
                          },
                          {
                            label: "Price Ascending",
                            value: "idc_products_price_asc",
                          },
                          {
                            label: "Price Descending",
                            value: "idc_products_price_dec",
                          },
                        ]}
                      />
                    </div>

                    <div className="flex items-center space-x-2">
                      <span className="hidden w-16 text-sm md:inline-block md:w-auto text-idc-body">
                        Per page
                      </span>
                      <HitsPerPage
                        className="pr-3 bg-white border rounded hover:border-idc-border hover:shadow-lg"
                        items={[
                          {
                            label: "32 Products",
                            value: 32,
                          },
                          {
                            label: "64 Products",
                            value: 64,
                          },
                          {
                            label: "128 Products",
                            value: 128,
                          },
                        ]}
                        defaultRefinement={32}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <CurrentRefinements />
              </div>

              <div className="mt-8 listing-list">
                <Results />
              </div>

              {/* <NoResults /> */}

              <div className="mt-12">
                <Pagination
                  padding={3}
                  // totalPages={8}
                  showFirst={false}
                  showLast={false}
                />
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
    </Layout>
  )
}

export default SearchListing
